<template>
  <div class="w-100">
    <b-card
      footer-class="btn-plan-footer"
      :header-class="`${plan.color_class} header-card-membership`"
      :class="`${plan.color_class}-border container-card ${plan.is_active ? 'hover-active' : ''}`"
      :variant="`${plan.color_class}-border`"
    >
      <template #header>
        <span>{{  $t(`creator.displayNameMembership.${plan.display_name}`) }}</span>
      </template>
      <div class="container-data">
        <div class="mt-1">
          <div class="mb-1">
            <span :class="`text-price ${plan.color_class}-color`">${{ plan.name.toLowerCase() === 'free' ? '0' : plan.price_month.replace('.00', '') }}</span>
            <span class="text-month">{{ plan.name.toLowerCase() === 'free' ? 'free forever' : $t('monthPlan') }}</span>
          </div>
          <div class="d-flex flex-column text-left">
            <span class="avenir-medium border-bottom pb-1 mb-1">{{  $t(`creator.descriptionCardMembership.${plan.display_name}`) }}</span>
            
            <strong class="w-100 text-left mb-1">{{$t('creator.include')}} </strong>
            <div class="border-bottom mb-1 pb-1">
              <p
                class="text-muted-custom-plans"
                v-for="(item, index) in plan.start_elements"
                :key="`start_elements_${index}`"
                >{{  $t(`creator.creditsMonth.${item.text}`) }}
  
                <span v-if="item.small_text" class="d-block small-text">{{ $t('creator.toMonth') }}</span>
              </p>
            </div>
            <div class="mb-1 pb-1" :class="{'border-bottom': plan.secondary_elements}">
              <p
                class="text-muted-custom-plans"
                v-for="(item, index) in plan.main_elements"
                :key="`main_elements_${index}`"
                >
                {{  $t(`creator.mainElements.${item.text}`) }}
                <span v-if="item.small_text" class="d-block small-text">{{ $t('creator.toMonth') }}</span>
              </p>
            </div>
            <div>
              <p
                class="text-muted-custom-plans"
                v-for="(item, index) in plan.secondary_elements"
                :key="`secondary_elements_${index}`"
                >{{ $t(`creator.secondaryElements.${getSafeKey(item.text)}`) }}
                <span v-if="item.small_text" class="d-block small-text">{{ $t('creator.toMonth') }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- HOVER CONTAINER IN CARDS -->
      <div :class="`${getBackgroundModal(plan.color_class, 0.9)} hover-card-plan`" ref="hover-card-plan">
        <div class="title-current-plan"><strong :class="`${plan.name === 'Free' ? 'text-dark' : 'text-white'} text-center`">{{$t('creator.currentPlan')}} "{{  $t(`creator.displayNameMembership.${plan.display_name}`) }}"</strong></div>
        <div class="container-cancel-sucription text-center" v-if="status_plan.status === null">
          <span>{{$t('creator.planFree')}} "{{  $t(`creator.displayNameMembership.${plan.display_name}`) }}"{{$t('creator.textFree')}}</span>
        </div>
        <div class="container-cancel-sucription text-center" v-if="status_plan.status">
          <div class="text-center">
            <p>
              {{(status_plan.cancel_at_period_end) ? $t('creator.suscriptionExpired') : $t('creator.nextBill')}}
              {{getDatev2(new Date(status_plan.next_charge))}}</p>
            <div class="d-flex align-items-center justify-content-center">
              <div class="d-flex align-items-center">
                <div>
                  <b-img v-if="status_plan.payment_method.card_brand !== 'unknown'" :src="require(`@/assets/images/card-brands/${status_plan.payment_method.card_brand.toLowerCase()}.svg`)" class="card-brand-img"></b-img>
                </div>
                <div class="ml-1 text-left">
                  <p class="m-0"> **** **** **** {{status_plan.payment_method.card_last4}}</p>
                  <p class="m-0">{{status_plan.payment_method.card_exp_month}}/{{status_plan.payment_method.card_exp_year.toString().slice(-2)}}</p>
                </div>
              </div>
            </div>
          </div>
          <b-button variant="danger" class="mt-3" @click="cancelSuscription" v-if="!status_plan.cancel_at_period_end">{{$t('creator.cancelSuscription')}}</b-button>
        </div>
      </div>
      <!-- -------------------------- -->
      <template #footer >
        <b-button
          :hidden="plan.is_active"
          :class="`${plan.color_class}-btn button-plan`"
          :variant="`${plan.color_class}-btn`"
          @click="openModal"
        >
          {{(getUserData().current_plan.name !== 'Free') && plan.name === 'Free' ? $t('memberships.downgradePlan') : `${$t('creator.updated_at')} ${$t(`creator.displayNameMembership.${plan.display_name}`)}`}}
        </b-button>
      </template>
    </b-card>
    <modal-membership-group
      :open_modal_plans_group="open_modal_plans_group" 
      :key="update_modal"
      :month_dad="true"
      :number_plan_selected="number_plan_selected - 1"
    />
  </div>
</template>
  
<script>
import { 
  BCard, 
  BButton,
  BImg,
} from "bootstrap-vue";
import service from "@/services/proposals";
import { getUserData, setPermissions, setuserData } from '@/libs/utils/user';
import { getDatev2 } from "@/libs/utils/times";
import abilities from '@/libs/acl/abilities'

export default {
  name: 'cardMembership',
  components: {
    BCard,
    BButton,
    BImg,
    ModalMembershipGroup: () => import('@/views/components/modal/ModalMembershipGroup.vue'),
  },
  props: {
    plan: {
      type: Object,
    },
    status_plan: {
      type: Object
    },
    number_plan_selected: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      setuserData,
      setPermissions,
      getUserData,
      getDatev2,
      open_modal: false,
      price_selected: '',
      prices_modal: [],
      update_modal: 0,
      open_modal_plans_group: false,
    }
  },
  methods: {
    getSafeKey(text) {
      const translationKeyMap = {
        "17.5% de Comisión en Retiros": "17CommissionOnWithdrawals",
        "10% de Comisión en Retiros": "10CommissionOnWithdrawals",
        "45 días para retiro de dinero": "45ForWithdrawal",
        "30 días para retiro de dinero": "30Withdrawal"
      };
      return translationKeyMap[text] || text;
    },
    cancelSuscription(downgrade = false) {
      this.$swal({
        title: `${downgrade ? this.$t('creator.downgrade') : this.$t('creator.cancelMembership')}`,
        text: `${this.$t('creator.youSure')} ${this.plan.display_name} ? `,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('creator.yesIam'),
        cancelButtonText: this.$t('creator.noIam'),
        heightAuto: false,
        customClass: {
          confirmButton: 'btn confirm-button',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          service.cancelPlan().then(response => {
            this.setUser(response)
          });
        }
      });
    },
    setUser (data) {
      setPermissions(data.user);
      localStorage.removeItem('userData');
      const read_ability = abilities.getAbilitie(data.user)

      setuserData(data, read_ability)
      this.$ability.update(read_ability)
      this.$store.commit('appConfig/UPDATE_IS_VERIFIED', data.user.is_verified)
      window.location.reload()
    },
    openModal() {
      if (this.plan.name !== 'Free') {
        this.open_modal_plans_group = true;
        this.update_modal += 1;
      } else {
        this.cancelSuscription(true);
      }
    },
    getBackgroundModal(background_color) {
      return `background: ${background_color}`;
    }
  }
};
</script>
<style>
.btn-plan-footer {
  display: flex;
  justify-content: center;
  border-top: none !important;
}
</style>
<style scoped lang="scss">
.text-muted-custom-plans {
  color: #6c757d;
  font-family: 'avenir-medium';
  margin-bottom: 0.9em;
  .small-text {
    font-size: 11px;
  }
}
.card-brand-img {
  width: 35px;
  height: 35px;
}
.title-current-plan {
  font-size: 16px;
  text-align: center;
  position: absolute;
  width: 100%;
  left: 0;
}
.container-cancel-sucription {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  text-shadow: 0px 0px 2px black;
  /* flex-wrap: wrap; */
}
.payment-title {
  display: block;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.2px;
  margin: 0 0 0.5em;
}
.hover-card-plan {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1em;
  color: white;
  opacity: 0.94;
  transform: translateY(1000px);
  /* animation: active_suscription 300ms; */
}
.button-plan {
  width: -webkit-fill-available;
}
.list-secondary {
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.5) !important;
  margin-top: 10px;
}
.text-check {
  margin-top: 5px;
}
.icon-star {
  width: 15px;
  height: 15px;
  color: #fcd147 !important;
  margin-right: 5px;
}
.text-month {
  font-size: 0.85rem !important;
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-price {
  font-size: 36px;
  /* font-weight: bold; */
  margin-right: 5px;
  font-family: 'avenir-medium';
}
.text-starting {
  display: block;
  text-transform: uppercase;
  color: #6c757d;
  font-size: 0.8rem;
  margin-top: 30px;
}
.container-data {
  text-align: center !important;
  margin: 0px;
}
.color-bg-free {
  background: #5d4ed4 !important;
  justify-content: center !important;
  padding: 17px !important;
  font-weight: bold;
  color: white;
  border-radius: 0.7rem 0.7rem 0 0 !important;
}
.color-bg-pro {
  // este es plan ia 
  background: rgb(105, 71, 188);
  background: linear-gradient(120deg, rgb(105, 71, 188), rgb(153, 62, 144));
  justify-content: center !important;
  padding: 17px !important;
  font-weight: bold;
  color: white;
}
//este es plan ia+ y premium+
.color-bg-marketer {
  background: linear-gradient(225deg,#068eb5 0%,#9b51e0 47%);
  justify-content: center !important;
  padding: 17px !important;
  font-weight: bold;
  color: white;
  border-radius: 0.7rem 0.7rem 0 0 !important;
}
//este es plan pro y premiun
.color-bg-marketer-plus {
  background: #5675b8;
  background: linear-gradient(90deg, #5675b8 0%, #05a3bc 100%);
  justify-content: center !important;
  padding: 17px !important;
  font-weight: bold;
  color: white;
  border-radius: 0.7rem 0.7rem 0 0 !important;
}
.color-bg-pro-btn {
  height: 3.125rem;
  background: rgb(255,255,255);
  background-color: rgb(105, 71, 188);
  color: white !important;;
  &:hover {
    background-color: rgba(104, 71, 188, 0.808);
  }
}
.color-bg-free-btn {
  height: 3.125rem;
  background: rgb(206, 212, 218) !important;
  color: white !important;
}
.color-bg-free-color {
  color: black;
}
.color-bg-pro-color {
  color: rgb(105, 71, 188);
}
.color-bg-marketer-color {
  color: #9b51e0;
}
.color-bg-marketer-plus-color {
  color: #5675b8;
}
.color-bg-free-btn:hover {
  background: rgba(180, 186, 192, 0.768) !important;
}
.color-bg-marketer-btn {
  height: 3.125rem;
  background: linear-gradient(225deg,rgba(6,142,181) 0%,rgb(155,81,224) 47%);
  color: white !important;
  &:hover {
    background: linear-gradient(225deg,rgba(6, 143, 181, 0.781) 0%,rgba(155, 81, 224, 0.829) 47%);
  }
}

.color-bg-marketer-plus-btn {
  height: 3.125rem;
  background: #5675b8;
  background: linear-gradient(90deg, #5675b8 0%, #05a3bc 100%);
  color: white !important;

  &:hover {
    background: linear-gradient(90deg, #5675b8 0%, #05a3bc 100%);

  }
}
.color-bg-pro-badge {
  background: rgb(105, 71, 188);
  width: 100%;
  font-weight: normal;
  padding: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.color-bg-free-badge {
  background: rgb(206, 212, 218) !important;
  width: 100%;
  font-weight: normal;
  padding: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.color-bg-marketer-badge {
  background: rgb(201, 16, 94);
  width: 100%;
  font-weight: normal;
  padding: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.color-bg-free-border {
  border: 1px solid rgb(206, 212, 218) !important;
  margin: 10px;
}
.color-bg-pro-border {
  border: 1px solid rgb(105, 71, 188);
  margin: 10px;
}
.color-bg-marketer-border {
  border: 1px solid rgb(155,81,224);
  margin: 10px;
}
.color-bg-marketer-plus-border {
  border: 1px solid #5675b8;
  margin: 10px;
}
.container-card {
  width: 100%;
  height: 95%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  border-radius: 1rem !important;
  overflow: hidden;
}
.container-card:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset !important;
}
.hover-active:hover .hover-card-plan {
  transform: translateY(0);
  transition: all 500ms;
}
.hover-active:not(:hover) .hover-card-plan {
  transition: all 500ms;
}
@keyframes active_suscription {
  0% {
    transform: translateY(500px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>