<template>
  <div>
  <b-tooltip target="email-info">{{$t('emailInfo')}}</b-tooltip>
  <b-card 
    class="card-container"
    body-class="border-card-info"
    header-class="border-header"
    >
    <template #header>
      <span class="title-text">{{$t('titleUserEmail')}} <b-icon icon="question-circle" id="email-info"></b-icon></span> 
    </template>
    <div class="mt-2">
      <span class="card-subtitle-preference d-flex align-items-center">
        {{$t('currentUserEmail')}} 
        <b-badge  pill :class="profile.is_verified ? 'verified-email' : 'not-verified-email'" v-if="loaded_service"> {{ profile.is_verified ? $t("creator.verified"): $t("creator.notVerified")}}</b-badge> 
        <b-skeleton class="skeleton-badge-settings-email" type="badge" v-else></b-skeleton>

      </span>
      <div class="d-flex" v-if="loaded_service">
        <span class="user-email-preference"> {{ profile.email }}</span>
      </div>
      <b-skeleton class="col-2" v-else></b-skeleton>

    </div>
   </b-card>

  <b-tooltip target="password-info">{{$t('passwordInfo')}}</b-tooltip>
  <b-card
      class="card-container"
      body-class="border-card-info"
      header-class="border-header" >
    <template #header>
      <span class="title-text">{{$t('titlePassword')}} <b-icon icon="question-circle" id="password-info"></b-icon></span> 
    </template>
    <!-- form -->
    <validation-observer ref="updatePassword" #default="{invalid}">
      <b-form class="mt-2" @submit.prevent="changePass">
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group>
              <label for="account-old-password">{{$t('oldPassword')}}</label>
              <validation-provider
                #default="{ errors }"
                :name="$t('oldPassword')"
                :vid="$t('oldPassword')"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-old-password"
                    v-model="objPass.last_pass"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    :class="{'is-invalid': !!errors.length}"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('oldPassword')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group>
              <label for="account-new-password">{{$t('newPassword')}}</label>
              <validation-provider
                #default="{ errors }"
                :name="$t('newPassword')"
                :vid="$t('newPassword')"

              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="objPass.new_pass"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    :placeholder="$t('newPassword')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group>
              <label for="account-retype-new-password">{{$t('retypePassword')}}</label>
              <validation-provider
                #default="{ errors }"
                :name="$t('retypePassword')"
                :vid="$t('retypePassword')"
                :rules="`confirmed:${$t('newPassword')}`"

              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="objPass.new_pass_check"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    :placeholder="$t('retypePassword')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12 justify-content-end d-flex">
            <b-button
              variant="button-save"
              type="reset"
              class="mt-2 button-save mr-1"
              @click="resetForm()"
            >
              {{$t('reset')}}
            </b-button>
            <b-button 
              variant="button-save"
              class="mt-2 button-save"
              type="submit"
              :disabled="invalid || objPass.last_pass.length === 0 || objPass.new_pass.length === 0 || objPass.new_pass_check.length === 0"
            >
              {{$t('save')}}
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>

  <b-tooltip target="payout-info">{{$t('payoutInfo')}}</b-tooltip>
  
  <b-tooltip target="vanity-info">{{$t('vanityInfo')}}</b-tooltip>
  <b-card 
    v-if="profile.main_group.name === 'creator' && (profile.meta_info && profile.meta_info.vanity_name)"
    class="card-container"
    body-class="border-card-info"
    header-class="border-header"
    >
    <template #header>
      <span class="title-text">Vanity Url <b-icon icon="question-circle" id="vanity-info"></b-icon></span> 
    </template>
    <div class="mt-2">
    <validation-observer
      ref="updateVanityName"
    >
      <b-form-group :label="`${domain}/@${vanity_name}`">
        <validation-provider rules="required">
          <b-input-group prepend="@" :class="stateVanity() === null ? '' : stateVanity() ? 'is-valid' : 'is-invalid'">
            <b-form-input
              v-model="vanity_name"
              placeholder="Vanity name"
              size="lg"
              :state="stateVanity()"
            />
          </b-input-group>
          <small class="mt-1 d-block text-danger" v-if="is_vanity_in_use && (profile.meta_info && profile.meta_info.vanity_name) && profile.meta_info.vanity_name !== vanity_name">{{$t('vanity_in_use')}}</small>
        </validation-provider>
      </b-form-group>
      <b-col cols="12 justify-content-end d-flex p-0">
        <b-button 
          variant="button-save"
          class="mt-2 button-save"
          type="submit"
          :disabled="!stateVanity()"
          @click="updateVanityName"
        >
          {{$t('save')}}
        </b-button>
      </b-col>
    </validation-observer>
      
    </div>

   </b-card>
</div>
</template>

<script>
import {
  BButton, 
  BForm, 
  BFormGroup, 
  BFormInput, 
  BRow, 
  BCol, 
  BCard, 
  BInputGroup, 
  BInputGroupAppend, 
  BBadge, 
  BTooltip,
  BSkeleton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import service from '@/services/others'
import { confirmed} from '@validations'
import { setuserData, setPermissions } from '@/libs/utils/user';
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import axios from 'axios';
import abilities from '@/libs/acl/abilities';
import config from '@/services/config';

export default {
  components: {
    BTooltip,
    BBadge,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BSkeleton,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      setPermissions,
      setuserData,
      profile: {
        main_group: {
          name:''
        }
      },
      objPass: {
        last_pass: '',
        new_pass: '',
        new_pass_check: ''
      },
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      vanity_name: '',
      cancel_ws: null,
      is_vanity_in_use: false,
      confirmed,
      domain: window.origin,
      loaded_service: false,
    }
  },
  created () {
    const user = JSON.parse(localStorage.userData);
    this.profile.main_group.name = user.main_group.name;
    this.getProfile();
    localize('es')
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    stateVanity() {
      if (this.profile.meta_info && this.profile.meta_info.vanity_name) {
        if (this.profile.meta_info.vanity_name === this.vanity_name) return null
        else return !this.is_vanity_in_use;
      }
    },
    getProfile () {
      service.getProfile()
        .then(response => {
          this.profile = response.user
          this.vanity_name = this.profile.meta_info && this.profile.meta_info.vanity_name ? this.profile.meta_info.vanity_name : ''
          this.loaded_service = true;
        })
    },
    updateVanityName () {
      const meta = {
        vanity_name: this.vanity_name
      }
      service.updateProfile(meta)
        .then(response => {
          setPermissions(response.user);
          this.readAbility = abilities.getAbilitie(response.user) 

          setuserData(response, this.readAbility)
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              position: 'top-right',
              props: {
                title: this.$t('dataUpdated'),
                icon: 'CheckCircleIcon',
                variant: 'success',
                text:  this.$t('dataSuccess')
              }
            })
          })
        }).catch(error => {
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              position: 'top-right',
              props: {
                title:  this.$t('dataError'),
                text: this.getError(error.response.data.response),
                icon: 'CoffeeIcon',
                variant: 'danger',
              }
            })
          })
        })
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    changePass() {
      service.updatePassword(this.objPass)
        .then((response) => {
          if (response.status >= 400) {
            loadToastificationContent().then((component) => {
              this.$toast({
                component: component.default,
                position: 'top-right',
                props: {
                  title: this.$t('passwordFailed'),
                  icon: 'XIcon',
                  variant: 'danger',
                  text: this.$t('passwordError')
                }
              })
            })
          } else {
            loadToastificationContent().then((component) => {
              this.$toast({
                component: component.default,
                position: 'top-right',
                props: {
                  title: this.$t('passwordUpdated'),
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                }
              })
            })
            this.resetForm();
          }
        });
    },
    resetForm() {
      this.objPass = {
        last_pass: '',
        new_pass: '',
        new_pass_check: ''
      }
    },
    isVanityInUse(vanity_name_input) {
      const CancelToken = axios.CancelToken;
      if (this.cancel_ws) this.cancel_ws()
      const self = this;
      axios({
        method: 'post',
        url: `${config.urlCore}/api/v3/profile/validate/vanity/`,
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        data: {
          vanity_name: vanity_name_input
        },
        cancelToken: new CancelToken(function executor(c) {
          self.cancel_ws = c;
        })
      }).then((response) => {
        this.is_vanity_in_use = response.data.response.ready_vanity_name
      })
    }
  },
  watch: {
    vanity_name(val) {
      if (val.length > 0) this.isVanityInUse(val);
      else this.is_vanity_in_use = true
    }
  }
}
</script>
<style scoped>
.skeleton-badge-settings-email {
  width: 100px;
  height: 20px;
  border-radius: 30px;
  margin-left: 1em;
}
.user-email-preference {
  font-weight: bold;
  color: #495057
}
.card-container {
  border-radius: 0.75rem !important;
}
.border-card-info {
  border: 0.0625rem solid rgba(222,226,230,.7);
  border-top: none;
  border-radius: 0rem 0rem 0.75rem 0.75rem;
}
.border-header {
  border: 0.0625rem solid rgba(222,226,230,.7) !important;
  border-radius: 0.75rem 0.75rem 0rem 0rem !important;
}
.title-text {
  font-weight: bolder !important;
}
.card-subtitle-preference {
  display: block;
  text-transform: uppercase;
  color: #6c757d;
  font-size: .80rem;
}
.verified-email {
  background: #2f9d76;
  margin-left: 3px;
}
.not-verified-email {
  background: #b41212;
  margin-left: 3px;
}
.link-update {
  margin-left: 3px;
  color: #55a6c4;
  cursor: text;
}
</style>
