<template>
  <div>
  <b-card 
    class="card-container"
    body-class="border-card-avatar"
    header-class="header-color"
    >
    <template #header> 
      <b-avatar 
        variant="light"
        :src="profile_image"
        size="10em" 
        class="avatar-profile"
        v-if="loader_profile"
      >
      </b-avatar>
      <b-skeleton type="avatar" class="skeleton-avatar-general-profile" v-else size="10em"></b-skeleton>

      <div>
      <b-avatar class="avatar-edit" variant="light" href="#" @click="openChangeFileAvatar()" v-if="loader_profile">
        <b-icon icon="pencil-fill"/>
      </b-avatar>
      </div>
        
    </template>
    <div class="mt-4">
       <span class="card-subtitle">{{$t('loginAs')}}</span>
       <div class="justify-content-between d-flex w-100">
        <span class="user-email" v-if="loader_profile">
          {{ profile.email }}
        </span> 
        <b-skeleton class="h-14 col-2" v-else></b-skeleton>
      </div>
    </div>

  </b-card>
  <b-tooltip target="overview-info">{{$t('overviewInfo')}}</b-tooltip>

  <b-card 
    class="card-container"
    body-class="border-card-info"
    header-class="border-header"
    >
    <template #header>
        <span class="title-text">{{$t('settingOverview')}} <b-icon icon="question-circle" id="overview-info"></b-icon></span> 
    </template>
    <validation-observer
      ref="updateProfileOverview"
      #default="{invalid}"
    >
      <b-form class="mt-2">
        <b-row>
          <b-col sm="6">
            <b-form-group>
              <label for="account-username">{{$t('name')}}</label>
              <validation-provider
                #default="{ errors }"
                :name="$t('name')"
                :vid="$t('name')"
                rules="required">
                <b-form-input
                  v-model="profile.first_name"
                  placeholder="Nombre"
                  name="Nombre"
                  :state="errors.length > 0 ? false:null"
                   v-if="loader_profile"
                />
                <b-skeleton type="input" v-else></b-skeleton>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>

          <b-col sm="6">
            <b-form-group>
              <label for="apellido">{{$t('lastname')}}</label>
              <validation-provider
                #default="{ errors }"
                :name="$t('lastname')"
                :vid="$t('lastname')"
                rules="required">
                <b-form-input
                  v-model="profile.last_name"
                  name="apellido"
                  placeholder="Apellido"
                  :state="errors.length > 0 ? false:null"
                   v-if="loader_profile"
                />
                <b-skeleton type="input" v-else></b-skeleton>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group >
              <label for="account-username">{{ $t('dateBirth') }} {{  }}</label>
              <validation-provider
                :name="$t('birthday')"
                :vid="$t('birthday')"
                rules="required">
                <input-calendar 
                  :real_date="birthday"
                  v-if="loader_profile"
                  ref="input_calendar"
                ></input-calendar>
                <b-skeleton type="input" v-else></b-skeleton>
              </validation-provider>
            </b-form-group>
          </b-col>
          
          <b-col md="12" v-if="profile.main_group.name === 'brand'">
            <b-form-group>
              <label for="position">{{$t('position')}}</label>
              <validation-provider
                #default="{ errors }"
                :vid="$t('position')"
                tag="div"
              >
                <b-form-select
                  id="position"
                  v-model="profile.position"
                  name="position"
                  :class="{'is-invalid': !!errors.length}"
                  :state="errors.length > 0 ? false:null"
                  :options="positions"
                  v-if="loader_profile"
                />
                <b-skeleton type="input" v-else></b-skeleton>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="profile.main_group.name === 'creator'">
            <b-form-group>
              <label for="question_text">{{$t('question_text')}}</label>
              <validation-provider
                #default="{ errors }"
                :name="$t('question_text')"
                :vid="$t('question_text')"
                tag="div"
                rules="required"
              >
                <b-form-textarea
                  id="question_text"
                  v-model="profile.question_text"
                  :class="{'is-invalid': !!errors.length}"
                  :state="errors.length > 0 ? false:null"
                   v-if="loader_profile"
                />
                <b-skeleton class="text-area-skeleton" v-else></b-skeleton>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        
          
          <b-col cols="12 justify-content-end d-flex">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="button-save"
              type="reset"
              class="mt-2 mr-1 button-save"
              @click.prevent="resetForm"
            >
              {{$t('reset')}}
            </b-button>
            <b-button
              :disabled="invalid"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="button-save"
              class="mt-2 button-save"
              @click="updateProfileOverview"
            >
              {{$t('save')}}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    
  </b-card>
  <b-tooltip target="basic-info">{{$t('infoBasic')}}</b-tooltip>

  <b-card
    class="card-container"
    body-class="border-card-info"
    header-class="border-header"
    >
    <template #header>
        <span class="title-text">{{ $t('basicInfo')  }} <b-icon icon="question-circle" id="basic-info"></b-icon></span> 
    </template>
    <validation-observer
      ref="updateProfileBasicInfo"
      #default="{invalid}"
    >
      <b-form class="mt-2">
        <b-row>
          <b-col sm="6">
            <b-form-group>
              <label for="telephone">{{$t('telephone')}}</label>
              <input-tel-brandme 
                v-if="profile.id" 
                :country_dad="profile.country.length > 0 ? profile.country : 'MX'" 
                :number_dad="telephone"
                @number="updateTelephone"
              ></input-tel-brandme>
              <b-skeleton type="input" v-else></b-skeleton>
            </b-form-group>
          </b-col>
          
          <b-col sm="6">
              <span>{{ $t('spokenLanguage') }}</span> <b-badge variant="color-badge" pill class="color-badge">{{ $t('maxLanguage') }}</b-badge>
              <b-row class="mr-1 ml-0">
              <b-button
                v-model="languages_selected"
                :variant="isLanguageSelected(language)[0]"
                :class="isLanguageSelected(language)[0]"
                pill
                v-for="(language, index) in $t('creator.languages')"
                :key="index"
                @click="addOrRemoveLanguage(language)">
                <b-icon :icon="isLanguageSelected(language)[1]"></b-icon>
                {{ language.text}}
              </b-button>
            </b-row>
            <div v-if="languages_selected.length > 4" class="alert alert-warning msj-warning" role="alert">
             <div>
              <feather-icon icon="InfoIcon" class="mr-1" size="20"/> <span>{{ $t('alertLanguage') }}</span>
             </div> 
            </div>
              
          </b-col>
          
          <b-col cols="12 justify-content-end d-flex">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="button-save"
              type="reset"
              class="mt-2 mr-1 button-save"
              @click.prevent="resetForm"
            >
              {{$t('reset')}}
            </b-button>
            <b-button 
              :disabled="invalid || languages_selected.length > 5 || !state_telephone"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="button-save"
              class="mt-2 button-save"
              @click="updateProfileBasicInfo"
            >
              {{$t('save')}}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
  
  <b-card 
    class="card-container"
    body-class="border-card-info"
    header-class="border-header"
    >
    <template #header>
        <span class="title-text">{{$t('address')}} <b-icon icon="question-circle" id="overview-info"></b-icon></span> 
    </template>
    <validation-observer
      ref="updateAddress"
      #default="{invalid}"
    >
      <b-form class="mt-2">
        <b-row>
          <b-col sm="6">
            <b-form-group>
              <label for="account-username">{{$t('city')}}</label>
              <validation-provider
                #default="{ errors }"
                :name="$t('city')"
                :vid="$t('city')"
                rules="required">
                <b-form-input
                  v-model="city"
                  :placeholder="$t('city')"
                  :name="$t('city')"
                  :state="errors.length > 0 ? false:null"
                   v-if="loader_profile"
                />
                <b-skeleton type="input" v-else></b-skeleton>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group>
              <label for="account-username">{{$t('street')}}</label>
              <validation-provider
                #default="{ errors }"
                :name="$t('street')"
                :vid="$t('street')"
                rules="required">
                <b-form-input
                  v-model="street"
                  :placeholder="$t('street')"
                  :name="$t('street')"
                  :state="errors.length > 0 ? false:null"
                  v-if="loader_profile"
                />
                <b-skeleton type="input" v-else></b-skeleton>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group>
              <label for="account-username">{{$t('numberExtenal')}}</label>
              <validation-provider
                #default="{ errors }"
                :name="$t('numberExtenal')"
                :vid="$t('numberExtenal')"
                rules="required">
                <b-form-input
                  v-model="external_number"
                  :placeholder="$t('numberExtenal')"
                  :name="$t('numberExtenal')"
                  :state="errors.length > 0 ? false:null"
                  v-if="loader_profile"
                />
                <b-skeleton type="input" v-else></b-skeleton>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group>
              <label for="account-username">{{$t('numberIntenal')}}</label>
                <b-form-input
                  v-model="internal_number"
                  :placeholder="$t('numberIntenal')"
                  :name="$t('numberIntenal')"
                  v-if="loader_profile"
                />
                <b-skeleton type="input" v-else></b-skeleton>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group>
              <label for="account-username">{{$t('suburb')}}</label>
              <validation-provider
                #default="{ errors }"
                :name="$t('suburb')"
                :vid="$t('suburb')"
              >
                <b-form-input
                  v-model="suburb"
                  :placeholder="$t('suburb')"
                  :name="$t('suburb')"
                  :state="errors.length > 0 ? false:null"
                  v-if="loader_profile"
                />
                <b-skeleton type="input" v-else></b-skeleton>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group>
              <label for="account-username">{{$t('state')}}</label>
              <validation-provider
                #default="{ errors }"
                :name="$t('state')"
                :vid="$t('state')"
                rules="required">
                <b-form-input
                  v-model="state"
                  :placeholder="$t('state')"
                  :name="$t('state')"
                  :state="errors.length > 0 ? false:null"
                  v-if="loader_profile"
                />
                <b-skeleton type="input" v-else></b-skeleton>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group>
              <label for="account-username">{{$t('colony')}}</label>
              <validation-provider
                #default="{ errors }"
                :name="$t('colony')"
                :vid="$t('colony')"
              >
                <b-form-input
                  v-model="colony"
                  :placeholder="$t('colony')"
                  :name="$t('colony')"
                  :state="errors.length > 0 ? false:null"
                  v-if="loader_profile"
                />
                <b-skeleton type="input" v-else></b-skeleton>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group>
              <label for="account-username">{{$t('zipCode')}}</label>
              <validation-provider
                #default="{ errors }"
                :name="$t('zipCode')"
                :vid="$t('zipCode')"
                rules="required">
                <b-form-input
                  v-model="zip_code"
                  :placeholder="$t('zipCode')"
                  :name="$t('zipCode')"
                  :state="errors.length > 0 ? false:null"
                  v-if="loader_profile"
                />
                <b-skeleton type="input" v-else></b-skeleton>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12 justify-content-end d-flex">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="button-save"
              type="reset"
              class="mt-2 mr-1 button-save"
              @click.prevent="resetForm"
            >
              {{$t('reset')}}
            </b-button>
            <b-button
              :disabled="invalid"
              variant="button-save"
              class="mt-2 button-save"
              @click="updateAddress"
            >
              {{$t('save')}}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    
  </b-card>
  <b-card header-class="border-header" body-class="border-card-info">
    <template #header>
      <span class="title-text ">{{$t('deleteAccount')}} </span> 
    </template>

    <b-row class="mt-2">
      <b-col>
        <p class="avenir-medium">{{ $t('deleteAccountText') }}</p>
        <b-form @submit.prevent="deleteAccountAction">
          <b-form-checkbox
            v-model="delete_account"
            :value="true"
            :unchecked-value="false"
            class="mb-1"
          >
           {{ $t('confirmDeleteAccount') }}
          </b-form-checkbox>

          <b-button type="submit" variant="danger" class="float-right" :disabled="!delete_account">{{ $t('deleteAccount') }}</b-button>
        </b-form>
      </b-col>
    </b-row>

  </b-card>

    <b-form-file
      v-model="avatar"
      accept=".jpg, .png"
      @change="changeAvatar"
      class="d-none"
      ref="input-file-avatar"
    ></b-form-file>
    <sider-updated-network
      class="open-sidebar-search"
      @close_siderbar_update="closeSiderUpdateNetwork"
      @updated_network="getNetworks"
      :show_sider_update="show_sider_update"
      :key="show_sider_update"
      :networkUpdate="networkUpdate"
      />
    <sider-network 
      class="open-sidebar-search"
      @close_siderbar="closeSiderNetwork"
      @updated_network="getNetworks"
      :show_sider_network="show_sider_network"
      v-if="show_sider_network"
      />
  </div>
</template>

<script>
import {
  BButton, 
  BForm, 
  BFormGroup, 
  BFormInput, 
  BFormTextarea,
  BFormCheckbox,
  BRow, 
  BCol, 
  BCard, 
  BFormSelect, 
  BFormFile, 
  BAvatar, 
  BIcon,
  BBadge, 
  BTooltip,
  BSkeleton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import others from '@/services/others'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, length } from '@validations'
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import profile_services from '@/services/settings';
import abilities from '@/libs/acl/abilities';
import service from "@/services/dashboard";
import siderUpdatedNetwork from "@/views/pages/proposals/stepper/siderUpdatedNetwork.vue";
import siderNetwork from "@/views/pages/proposals/stepper/siderNetwork.vue";
import InputTelBrandme from '@/views/components/inputs/InputTelBrandme.vue'
import { getURL } from '@/libs/utils/urls';
import { setPermissions, setuserData } from '@/libs/utils/user'

export default {
  components: {
    BSkeleton,
    siderNetwork,
    siderUpdatedNetwork,
    BTooltip,
    BBadge,
    BFormCheckbox,
    BIcon,
    BAvatar,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BRow,
    BCol,
    BCard,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    InputTelBrandme,
    inputCalendar: () => import('@/views/components/inputs/inputCalendar.vue')
  },
  directives: {
    Ripple
  },
  data () {
    return {
      loader_profile: false,
      networkUpdate: {
        username: '',
        followers: '',
      },
      show_sider_network: false,
      show_sider_update: false,
      networks: [],
      readAbility: [],
      profile: {
        main_group: {
          name:''
        }
      },
      avatar: null,
      positions: this.$t('positions'),
      required,
      length,
      errorPhone: false,
      telephone: '',
      birthday: '',
      city: '',
      street: '',
      external_number: '',
      internal_number: '',
      suburb: '',
      state: '',
      colony: '',
      zip_code: '',
      profile_image: '',
      languages: [
        {"value": "arabic", "text": "arabic"},
        {"value": "chinese", "text": "chinese"},
        {"value": "english", "text":"english"},
        {"value": "french", "text":"french"}
      ],
      languages_selected: [],
      genders: [
        {"value": "male", "text": "male"},
        {"value": "female", "text": "female"},
      ],
      state_telephone: false,
      delete_account: false,
    }
  },
  created () {
    localize(this.$i18n.locale)
    const user = JSON.parse(localStorage.userData);
    this.profile.main_group.name = user.main_group.name;
    this.telephone = this.profile.telephone
    this.profile.gender = this.profile.gender ? this.profile.gender.toLowerCase() : '';
    this.getNetworks();
    this.getProfile();
  },
  methods: {
    showToast(
      title,
      text,
      variant,
      icon,
      position = "top-right"
    ) {
      loadToastificationContent().then((component) => {
        this.$toast({
          component: component.default,
          position,
          props: {
            title,
            icon,
            variant,
            text,
          }
        })
      })
    },
    deleteAccountAction() {
      if (this.delete_account) {
        others.deleteAccountBrandme({}).then(() => {
          localStorage.clear();
          this.$router.push("/adios");
        })
      }
    },
    updateTelephone(telephone_data) {
      this.telephone = telephone_data.number;
      this.state_telephone = telephone_data.state;
      this.profile.country = telephone_data.country;
    },
    getProfile () {
      others.getProfile()
        .then(response => {
          this.profile = response.user
          this.telephone = this.profile.telephone
          this.zip_code = this.profile.meta_info && this.profile.meta_info.zip_code ? this.profile.meta_info.zip_code : ''
          this.colony = this.profile.meta_info && this.profile.meta_info.colony ? this.profile.meta_info.colony : ''
          this.state = this.profile.meta_info && this.profile.meta_info.state ? this.profile.meta_info.state : ''
          this.suburb = this.profile.meta_info && this.profile.meta_info.suburb ? this.profile.meta_info.suburb : ''
          this.street = this.profile.meta_info && this.profile.meta_info.street ? this.profile.meta_info.street : ''
          this.internal_number = this.profile.meta_info && this.profile.meta_info.internal_number ? this.profile.meta_info.internal_number : ''
          this.external_number = this.profile.meta_info && this.profile.meta_info.external_number ? this.profile.meta_info.external_number : ''
          this.city = this.profile.meta_info && this.profile.meta_info.city ? this.profile.meta_info.city : ''
          this.birthday = this.profile.meta_info && this.profile.meta_info.birthday ? this.profile.meta_info.birthday : ''
          this.languages_selected = this.profile.meta_info && this.profile.meta_info.languages ? this.profile.meta_info.languages.map(language => ({ value: language, text: this.$t('creator.languages').find(i => i.value === language).text })) : [] 
          this.profile_image = this.getImgProfile(this.profile.profile_image);
          setPermissions(response.user);
          this.readAbility = abilities.getAbilitie(response.user) 

          setuserData(response, this.readAbility)
          this.loader_profile = true
        })
    },
    clickEdit(item) {
      this.show_sider_update = true; 
      this.networkUpdate = item
    },
    isLanguageSelected(language) {
      return this.languages_selected.find((lan) => language.value === lan.value) !== undefined ? ['class-btn-check', 'check2'] : ['class-btn-plus', 'plus'];
    },
    addOrRemoveLanguage(language) {
      if (this.languages_selected.find((lan) => language.value === lan.value) !== undefined) {
        this.languages_selected = this.languages_selected.filter((lan) => lan.value !== language.value);
      } else if (this.languages_selected.length < 5) {
        this.languages_selected.push(language)
      }
    },
    redirectSocialSettings() {
      this.$router.push({name: 'pages-account-setting', params: { section: 'Social' } })
    },
    getLinkNetwork(profile) {
      if (profile.network === 'facebook' && profile.link) return profile.link
      return getURL(profile.network, profile.username)
    },
    closeSiderNetwork() {
      this.show_sider_network = false
    },
    openChangeFileAvatar() {
      this.avatar = null;
      this.$refs['input-file-avatar'].$refs['input'].click()
    },
    closeSiderUpdateNetwork() {
      this.show_sider_update = false
    },
    changeAvatar() {
      setTimeout(() => {
        if (this.avatar) {
          profile_services.changeAvatar(this.avatar).then(result => {
            this.avatar = null;
            const readAbility = abilities.getAbilitie(result.user)
            setuserData(result, readAbility);
            this.$root.$emit('profile_update');
            this.profile_image = this.getImgProfile(result.user.profile_image);
          }).catch(() => {
          });
        }
      }, 200);
    },
    getNetworks() {
      others.getSocialNetworks().then((response) => {
        this.networks = response.results;
      })
        .catch(() => {
        });
    },
    deleteNetwork(network) {
      const self = this
      self.$swal({
        title: this.$t('confirmDeleteNetworkTitle'),
        text: this.$t('confirmDeleteNetwork'),
        icon: 'warning',
        customClass: {
          confirmButton: 'btn confirm-button ',
          cancelButton: 'btn btn-outline-danger mr-1'
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t('msjConfirmNetwork'),
        cancelButtonText: this.$t('campaigns.msjCancel'),
        reverseButtons: true,
        heightAuto: false
      }).then(result => {
        if (result.value) {
          others.removeSocialNetwork(network.uuid).then(() => {
            this.showToast(
              this.$t("socialDeleted"),
              this.$t("socialDeleteddata"),
              "danger",
              "Trash2Icon"
            );
            this.getNetworks();
          });
        }
      })
    },
    resetForm () {
      this.getProfile()
    },
    getImgProfile(image_url) {
      if (image_url !== null && image_url !== "")
        return service.getCore + image_url;
      const img = require(`@/assets/images/avatars/${
        Math.floor(Math.random() * 4) + 1
      }.png`);
      return img;
    },
    updateProfileOverview () {
      this.birthday = this.$refs['input_calendar'].getDate();
      const meta = {
        first_name: this.profile.first_name,
        last_name: this.profile.last_name,
        question_text: this.profile.question_text,
        birthday: this.birthday
      }
      others.updateProfile(meta)
        .then(response => {
          if (response.status >= 400) {
            this.showToast(
              this.$t("errorTitle"),
              this.$t("errorBirthday"),
              "danger",
              "XIcon"
            );
          } else {
            this.readAbility = abilities.getAbilitie(response.user) 
            setPermissions(response.user);
            setuserData(response, this.readAbility)
            this.$root.$emit('profile_update');
            this.showToast(
              this.$t("dataUpdated"),
              this.$t("dataSuccess"),
              "success",
              "CheckCircleIcon"
            );
          }
        }).catch(error => {
          this.showToast(
            this.$t("dataError"),
            this.getError(error.response.data.response),
            "danger",
            "CoffeeIcon"
          );
        })
    },
    updateAddress () {
      const meta = {};
      if (this.city !== '') meta.city = this.city;
      if (this.street !== '') meta.street = this.street;
      if (this.external_number !== '') meta.external_number = this.external_number;
      if (this.internal_number !== '') meta.internal_number = this.internal_number;
      if (this.suburb !== '') meta.suburb = this.suburb;
      if (this.state !== '') meta.state = this.state;
      if (this.colony !== '') meta.colony = this.colony;
      if (this.zip_code !== '') meta.zipcode = this.zip_code;
      others.updateProfile(meta)
        .then(response => {
          setPermissions(response.user);
          this.readAbility = abilities.getAbilitie(response.user) 

          setuserData(response, this.readAbility)
          this.showToast(
            this.$t("dataUpdated"),
            this.$t("dataSuccess"),
            "success",
            "CheckCircleIcon"
          );
        }).catch(error => {
          this.showToast(
            this.$t("dataError"),
            this.getError(error.response.data.response),
            "danger",
            "CoffeeIcon"
          );
        })
    },
    updateProfileBasicInfo () {
      const format_languages = this.languages_selected.map(item => item.value)
      const meta = {
        telephone: this.telephone,
        country: this.profile.country,
        languages: format_languages,
        empty_languages: format_languages.length === 0
      }
      others.updateProfile(meta)
        .then(response => {
          setPermissions(response.user);
          this.readAbility = abilities.getAbilitie(response.user) 

          setuserData(response, this.readAbility)
          this.showToast(
            this.$t("dataUpdated"),
            this.$t("dataSuccess"),
            "success",
            "CheckCircleIcon"
          );
        }).catch(error => {

          this.showToast(
            this.$t("dataError"),
            this.getError(error.response.data.response),
            "danger",
            "CoffeeIcon"
          );
        })
    },
    getError(error) {
      if (error.first_name) return  this.$t('dataErrorname')
      if (error.last_name) return  this.$t('dataErrorlastname')
      if (error.gender) return 'Género es necesario'
    },
  },
  setup () {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer
    }
  }
}
</script>
<style scoped lang="scss">
.h-14 {
  height: 20px;
  margin: 0;
}
.text-area-skeleton {
  height: 68.52px
}
.skeleton-avatar-general-profile {
  background-color: rgb(223, 223, 223);
}
</style>
<style>
.container-loader-profile {
  border-radius: 0.75rem;
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(60px);
  z-index: 1000;
  right: 5px;
}
.loader-profile {
  z-index: 10;
  display: flex;
  justify-content: center;
}
.spinner-profile {
  margin-top: 14em;
  height: 6em;
  width: 6em;
  color: #acacac;
}
.container-title-sider {
  border: 1px solid rgb(206, 212, 218) !important;
}
.card-container {
  border-radius: 0.75rem !important;
}
.container-languages {
  margin: 0px 40px 0px 10px
}
.msj-warning {
  margin-top: 10px;
  padding: 10px;
  font-size: small;
}
.border-card-info {
  border: 0.0625rem solid rgba(222,226,230,.7);
  border-top: none;
  border-radius: 0rem 0rem 0.75rem 0.75rem;
}
.border-card-avatar {
  border: 0.0625rem solid rgba(222,226,230,.7);
  border-top: none;
  border-radius: 0rem 0rem 0.75rem 0.75rem;
}
.border-header {
  border: 0.0625rem solid rgba(222,226,230,.7) !important;
  border-radius: 0.75rem 0.75rem 0rem 0rem !important;
}
.image-network {
  width: 35px !important;
  height: 35px !important;
}
.country-selector__list__item {
  display: block;
  padding: 2em 1em;
}
.form-file-avatar {
  z-index: 0;
}
.header-color {
  background: linear-gradient(to left bottom, #7cd6af, #3e7ba0);
  justify-content: center !important;
  height: 130px;
  border: 0.0625rem solid rgba(222,226,230,.7) !important;
  border-radius: 0.75rem 0.75rem 0rem 0rem !important;
}
.card-subtitle {
  display: block;
  text-transform: uppercase;
  color: #6c757d;
  font-size: .85rem;
}
.user-email {
  font-weight: bold;
}
.link-update {
  text-decoration: none;
  color: #55a6c4 !important;
}
.avatar-profile {
  position: absolute;
  border: 0.125rem dashed rgba(222,226,230,.7);
  bottom: -20px;
  font-weight: 100 !important;
  padding: 3px;
}
.button-save {
  background-color: #fff;
  border-color: rgba(222,226,230,.7);
  height: 3.125rem;
  justify-content: center;
  color: #495057;
  position: relative;
}
.button-save:hover {
  box-shadow: rgba(0, 0, 0, 0) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset !important;
}
.avatar-edit {
  position: absolute;
  bottom: -17px;
  color: #495057;
  margin-left: 30px;
}
.avatar-edit:hover {
  box-shadow: rgba(0, 0, 0, 0) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset !important;
    color: black;
}
.title-text {
  font-weight: bolder !important;
}
.class-btn-plus {
  margin: 7px 4px 0px 0px;
  padding: 5px 13px 5px 13px;
  background-color: #fff !important;
  border-color: #ced4da !important;
  color: #6c757d !important;
}
.class-btn-check {
  margin: 7px 4px 0px 0px;
  padding: 5px 13px 5px 13px;
  background-color: #d5ebe4 !important;
  border-color: #d5ebe4 !important;
  color: #267e5e !important;
}
.color-badge {
  background: #FCD147;
  color: black;
  font-size: 75%;
  margin: 0px 0px 11px 0px
}
.open-sidebar-search > .b-sidebar-right {
  animation: sidebar 500ms;
}
@keyframes sidebar {
  0% {
    transform: translateX(500px)
  }
  100% {
    transform: translateX(0px)
  }
}
</style>